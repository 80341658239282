import React, {Component} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {connect} from "react-redux";

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {

/*
<Menu.Item key="achat">
  <Link to="/achat"><i className="icon icon-editor"/>
    <IntlMessages id="sidebar.achats"/></Link>
</Menu.Item>
*/


  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  getMenuADMINISTRATEUR = (selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest) => {

    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          </div>

          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-data-display"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>

              {isBack  &&
                <Menu.Item key="devis">
                  <Link to="/devis"><i className="icon icon-product-list"/>
                    <IntlMessages id="sidebar.devis"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="boncadeau">
                  <Link to="/boncadeau"><i className="icon icon-birthday"/>
                    <IntlMessages id="sidebar.bonCadeau"/></Link>
                </Menu.Item>
              }

              {!isBack  && IsModeTest === true &&
                <Menu.Item key="flotte">
                  <Link to="/flotte"><i className="icon icon-crm"/>
                    <IntlMessages id="sidebar.B2B.Flotte"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="boutique">
                  <Link to="/achat_telephone"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.B2B.Boutique"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="commande_client">
                  <Link to="/commande_client"><i className="icon icon-visits"/>
                    <IntlMessages id="sidebar.B2B.Commande"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="ligne_mobile">
                  <Link to="/ligne_mobile"><i className="icon icon-phone"/>
                    <IntlMessages id="sidebar.B2B.Ligne_Mobile"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <Menu.Item key="intervention">
                  <Link to="/intervention"><i className="icon icon-visits"/>
                    <IntlMessages id="sidebar.interventions"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="sav">
                  <Link to="/sav"><i className="icon icon-queries"/>
                    <IntlMessages id="sidebar.sav"/></Link>
                </Menu.Item>
              }

              <Menu.Item key="reprise">
                <Link to="/reprise"><i className="icon icon-select"/>
                  <IntlMessages id="sidebar.components.reprise"/></Link>
              </Menu.Item>


              {isBack  && IsPhonerBusiness &&
                <Menu.Item key="boutique_perso">
                  <Link to="/boutique_perso"><i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.boutique_perso"/></Link>
                </Menu.Item>
              }

              {!isBack  &&
                <Menu.Item key="impact_rse">
                  <Link to="/dashboard_rse"><i className="icon icon-chart-tree"/>
                    <IntlMessages id="sidebar.B2B.ImactRSE"/></Link>
                </Menu.Item>
              }

              {isBack  && IsPhonerBusiness &&
                <SubMenu key="submenu_telecom" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-orders"/>
                   <IntlMessages id="sidebar.components.telecom"/>
                   </span>}>

                     <Menu.Item key="flotte">
                       <Link to="/flotte_client"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Flotte"/></Link>
                     </Menu.Item>

                     <Menu.Item key="sim_all">
                       <Link to="/sim_all"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Sim"/></Link>
                     </Menu.Item>

                     <Menu.Item key="location">
                       <Link to="/location"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Location"/></Link>
                     </Menu.Item>

                     <Menu.Item key="type_cdr">
                       <Link to="/type_cdr"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.TypeCDR"/></Link>
                     </Menu.Item>

                </SubMenu>

              }

              {isBack  &&
                <SubMenu key="submenu_facture" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-revenue-new"/>
                   <IntlMessages id="sidebar.components.facture"/>
                   </span>}>

                  <Menu.Item key="facture">
                    <Link to="/facture"><i className="icon icon-editor"/>
                      <IntlMessages id="sidebar.B2B.Facture"/></Link>
                  </Menu.Item>

                  <Menu.Item key="avoir">
                    <Link to="/avoir"><i className="icon icon-inbuilt-apps"/>
                      <IntlMessages id="sidebar.avoir"/></Link>
                  </Menu.Item>

                  <Menu.Item key="remise">
                    <Link to="/remise"><i className="icon icon-frequent"/>
                      <IntlMessages id="sidebar.remise"/></Link>
                  </Menu.Item>

                  <Menu.Item key="bontreso">
                    <Link to="/bontreso"><i className="icon icon-tag-o"/>
                      <IntlMessages id="sidebar.bontreso"/></Link>
                  </Menu.Item>

                  <Menu.Item key="prelevement">
                    <Link to="/prelevement"><i className="icon icon-editor"/>
                      <IntlMessages id="sidebar.prelevement"/></Link>
                  </Menu.Item>

                </SubMenu>
              }
              {!isBack  &&
                <Menu.Item key="facture">
                  <Link to="/facture"><i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.B2B.Facture"/></Link>
                </Menu.Item>
              }


              {!isBack  &&
                <Menu.Item key="mes_documents">
                  <Link to="/documents"><i className="icon icon-folder-o"/>
                    <IntlMessages id="sidebar.B2B.Document"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="mon_compte">
                  <Link to="/mon_compte"><i className="icon icon-avatar"/>
                    <IntlMessages id="sidebar.B2B.Compte"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <SubMenu key="submenu_commandes_fournisseur" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.commande_fournisseur"/>
                  </span>}>


                  <Menu.Item key="achat">
                    <Link to="/achat"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.achats"/></Link>
                  </Menu.Item>

                  <Menu.Item key="commande_a_passer">
                    <Link to="/commande_a_passer"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.commande_a_passer"/></Link>
                  </Menu.Item>

                  <Menu.Item key="commande_fournisseur_list">
                    <Link to="/commande_fournisseur"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.commande_fournisseur_list"/></Link>
                  </Menu.Item>

                  <Menu.Item key="bons_reception">
                    <Link to="/bon_reception"><i className="icon icon-notification-new"/>
                    <IntlMessages id="sidebar.bons_reception"/></Link>
                  </Menu.Item>
                </SubMenu>
              }
              {isBack  &&
                <Menu.Item key="article">
                  <Link to="/article"><i className="icon icon-spin"/>
                    <IntlMessages id="sidebar.article"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <Menu.Item key="stock">
                  <Link to="/stock"><i className="icon icon-icon"/>
                    <IntlMessages id="sidebar.stock"/></Link>
                </Menu.Item>
              }

              {isBack &&
                <SubMenu key="submenu_composants" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-diamond"/>
                    <IntlMessages id="sidebar.components"/>
                  </span>}>

                  <Menu.Item key="composant">
                    <Link to="/composant"><i className="icon icon-widgets"/>
                      <IntlMessages id="sidebar.components.list"/></Link>
                  </Menu.Item>

                  <Menu.Item key="navette">
                    <Link to="/navette"><i className="icon icon-sent"/>
                      <IntlMessages id="sidebar.components.navette"/></Link>
                  </Menu.Item>

                </SubMenu>
              }


              {isBack &&
                <SubMenu key="submenu_reco" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-chart-tree"/>
                    <IntlMessages id="sidebar.reco"/>
                  </span>}>

                  <Menu.Item key="dashboard_reconditionnement">
                    <Link to="/stat_reco"><i className="icon icon-data-display"/>
                      <IntlMessages id="sidebar.components.dashboard_reconditionnement"/></Link>
                  </Menu.Item>

                  <Menu.Item key="reconditionnement">
                    <Link to="/reconditionnement"><i className="icon icon-chart-tree"/>
                      <IntlMessages id="sidebar.components.reconditionnement"/></Link>
                  </Menu.Item>



                </SubMenu>
              }





              {isBack  &&
                <Menu.Item key="client">
                  <Link to="/client"><i className="icon icon-signup"/>
                    <IntlMessages id="sidebar.client"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="parametrages">
                  <Link to="/parametrages"><i className="icon icon-setting"/>
                    <IntlMessages id="sidebar.parametrages"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="charges">
                  <Link to="/charges"><i className="icon icon-down"/>
                    <IntlMessages id="sidebar.charges"/></Link>
                </Menu.Item>
              }



              {isBack &&
                <SubMenu key="submenu_stat" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-diamond"/>
                    <IntlMessages id="sidebar.stats"/>
                  </span>}>

                  <Menu.Item key="stat_vente">
                    <Link to="/stat_vente"><i className="icon icon-widgets"/>
                      <IntlMessages id="sidebar.stats.statsVente"/></Link>
                  </Menu.Item>



                </SubMenu>
              }





            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );

  }

  getMenuTECHNICIEN = (selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest) => {
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          </div>

          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-data-display"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>

              {isBack  &&
                <Menu.Item key="devis">
                  <Link to="/devis"><i className="icon icon-product-list"/>
                    <IntlMessages id="sidebar.devis"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="boncadeau">
                  <Link to="/boncadeau"><i className="icon icon-birthday"/>
                    <IntlMessages id="sidebar.bonCadeau"/></Link>
                </Menu.Item>
              }

              {!isBack  && IsModeTest === true &&
                <Menu.Item key="flotte">
                  <Link to="/flotte"><i className="icon icon-crm"/>
                    <IntlMessages id="sidebar.B2B.Flotte"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="boutique">
                  <Link to="/achat_telephone"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.B2B.Boutique"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="commande_client">
                  <Link to="/commande_client"><i className="icon icon-visits"/>
                    <IntlMessages id="sidebar.B2B.Commande"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="ligne_mobile">
                  <Link to="/ligne_mobile"><i className="icon icon-phone"/>
                    <IntlMessages id="sidebar.B2B.Ligne_Mobile"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <Menu.Item key="intervention">
                  <Link to="/intervention"><i className="icon icon-visits"/>
                    <IntlMessages id="sidebar.interventions"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="sav">
                  <Link to="/sav"><i className="icon icon-queries"/>
                    <IntlMessages id="sidebar.sav"/></Link>
                </Menu.Item>
              }

              {isBack  &&

              <Menu.Item key="reprise">
                <Link to="/reprise"><i className="icon icon-select"/>
                  <IntlMessages id="sidebar.components.reprise"/></Link>
              </Menu.Item>

                }

              {isBack  && IsPhonerBusiness &&
                <Menu.Item key="boutique_perso">
                  <Link to="/boutique_perso"><i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.boutique_perso"/></Link>
                </Menu.Item>
              }

              {!isBack  &&
                <Menu.Item key="impact_rse">
                  <Link to="/dashboard_rse"><i className="icon icon-chart-tree"/>
                    <IntlMessages id="sidebar.B2B.ImactRSE"/></Link>
                </Menu.Item>
              }

              {isBack  && IsPhonerBusiness &&
                <SubMenu key="submenu_telecom" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-orders"/>
                   <IntlMessages id="sidebar.components.telecom"/>
                   </span>}>

                     <Menu.Item key="flotte">
                       <Link to="/flotte_client"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Flotte"/></Link>
                     </Menu.Item>

                     <Menu.Item key="sim_all">
                       <Link to="/sim_all"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Sim"/></Link>
                     </Menu.Item>

                     <Menu.Item key="location">
                       <Link to="/location"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Location"/></Link>
                     </Menu.Item>

                     <Menu.Item key="type_cdr">
                       <Link to="/type_cdr"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.TypeCDR"/></Link>
                     </Menu.Item>



                </SubMenu>

              }

              {isBack  &&
                <SubMenu key="submenu_facture" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-revenue-new"/>
                   <IntlMessages id="sidebar.components.facture"/>
                   </span>}>

                  <Menu.Item key="facture">
                    <Link to="/facture"><i className="icon icon-editor"/>
                      <IntlMessages id="sidebar.B2B.Facture"/></Link>
                  </Menu.Item>

                  <Menu.Item key="avoir">
                    <Link to="/avoir"><i className="icon icon-inbuilt-apps"/>
                      <IntlMessages id="sidebar.avoir"/></Link>
                  </Menu.Item>

                  <Menu.Item key="remise">
                    <Link to="/remise"><i className="icon icon-frequent"/>
                      <IntlMessages id="sidebar.remise"/></Link>
                  </Menu.Item>


                  <Menu.Item key="prelevement">
                    <Link to="/prelevement"><i className="icon icon-editor"/>
                      <IntlMessages id="sidebar.prelevement"/></Link>
                  </Menu.Item>

                </SubMenu>
              }
              {!isBack  &&
                <Menu.Item key="facture">
                  <Link to="/facture"><i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.B2B.Facture"/></Link>
                </Menu.Item>
              }


              {!isBack  &&
                <Menu.Item key="mes_documents">
                  <Link to="/documents"><i className="icon icon-folder-o"/>
                    <IntlMessages id="sidebar.B2B.Document"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="mon_compte">
                  <Link to="/mon_compte"><i className="icon icon-avatar"/>
                    <IntlMessages id="sidebar.B2B.Compte"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <SubMenu key="submenu_commandes_fournisseur" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.commande_fournisseur"/>
                  </span>}>


                  <Menu.Item key="commande_a_passer">
                    <Link to="/commande_a_passer"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.commande_a_passer"/></Link>
                  </Menu.Item>

                  <Menu.Item key="commande_fournisseur_list">
                    <Link to="/commande_fournisseur"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.commande_fournisseur_list"/></Link>
                  </Menu.Item>

                  <Menu.Item key="bons_reception">
                    <Link to="/bon_reception"><i className="icon icon-notification-new"/>
                    <IntlMessages id="sidebar.bons_reception"/></Link>
                  </Menu.Item>
                </SubMenu>
              }
              {isBack  &&
                <Menu.Item key="article">
                  <Link to="/article"><i className="icon icon-spin"/>
                    <IntlMessages id="sidebar.article"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <Menu.Item key="stock">
                  <Link to="/stock"><i className="icon icon-icon"/>
                    <IntlMessages id="sidebar.stock"/></Link>
                </Menu.Item>
              }

              {isBack &&
                <SubMenu key="submenu_composants" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-diamond"/>
                    <IntlMessages id="sidebar.components"/>
                  </span>}>

                  <Menu.Item key="composant">
                    <Link to="/composant"><i className="icon icon-widgets"/>
                      <IntlMessages id="sidebar.components.list"/></Link>
                  </Menu.Item>

                  <Menu.Item key="navette">
                    <Link to="/navette"><i className="icon icon-sent"/>
                      <IntlMessages id="sidebar.components.navette"/></Link>
                  </Menu.Item>
                  <Menu.Item key="reconditionnement">
                    <Link to="/reconditionnement"><i className="icon icon-chart-tree"/>
                      <IntlMessages id="sidebar.components.reconditionnement"/></Link>
                  </Menu.Item>

                </SubMenu>
              }

              {isBack  &&
                <Menu.Item key="client">
                  <Link to="/client"><i className="icon icon-signup"/>
                    <IntlMessages id="sidebar.client"/></Link>
                </Menu.Item>
              }

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }

  getMenuMANAGER = (selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest) => {
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          </div>

          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-data-display"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>

              {isBack  &&
                <Menu.Item key="devis">
                  <Link to="/devis"><i className="icon icon-product-list"/>
                    <IntlMessages id="sidebar.devis"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="boncadeau">
                  <Link to="/boncadeau"><i className="icon icon-birthday"/>
                    <IntlMessages id="sidebar.bonCadeau"/></Link>
                </Menu.Item>
              }

              {!isBack  && IsModeTest === true &&
                <Menu.Item key="flotte">
                  <Link to="/flotte"><i className="icon icon-crm"/>
                    <IntlMessages id="sidebar.B2B.Flotte"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="boutique">
                  <Link to="/achat_telephone"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.B2B.Boutique"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="commande_client">
                  <Link to="/commande_client"><i className="icon icon-visits"/>
                    <IntlMessages id="sidebar.B2B.Commande"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="ligne_mobile">
                  <Link to="/ligne_mobile"><i className="icon icon-phone"/>
                    <IntlMessages id="sidebar.B2B.Ligne_Mobile"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <Menu.Item key="intervention">
                  <Link to="/intervention"><i className="icon icon-visits"/>
                    <IntlMessages id="sidebar.interventions"/></Link>
                </Menu.Item>
              }

              {isBack  &&
                <Menu.Item key="sav">
                  <Link to="/sav"><i className="icon icon-queries"/>
                    <IntlMessages id="sidebar.sav"/></Link>
                </Menu.Item>
              }

              <Menu.Item key="reprise">
                <Link to="/reprise"><i className="icon icon-select"/>
                  <IntlMessages id="sidebar.components.reprise"/></Link>
              </Menu.Item>


              {isBack  && IsPhonerBusiness &&
                <Menu.Item key="boutique_perso">
                  <Link to="/boutique_perso"><i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.boutique_perso"/></Link>
                </Menu.Item>
              }

              {!isBack  &&
                <Menu.Item key="impact_rse">
                  <Link to="/dashboard_rse"><i className="icon icon-chart-tree"/>
                    <IntlMessages id="sidebar.B2B.ImactRSE"/></Link>
                </Menu.Item>
              }

              {isBack  && IsPhonerBusiness &&
                <SubMenu key="submenu_telecom" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-orders"/>
                   <IntlMessages id="sidebar.components.telecom"/>
                   </span>}>

                     <Menu.Item key="flotte">
                       <Link to="/flotte_client"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Flotte"/></Link>
                     </Menu.Item>

                     <Menu.Item key="sim_all">
                       <Link to="/sim_all"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Sim"/></Link>
                     </Menu.Item>

                     <Menu.Item key="location">
                       <Link to="/location"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.Location"/></Link>
                     </Menu.Item>

                     <Menu.Item key="type_cdr">
                       <Link to="/type_cdr"><i className="icon icon-crm"/>
                         <IntlMessages id="sidebar.TypeCDR"/></Link>
                     </Menu.Item>



                </SubMenu>

              }

              {isBack  &&
                <SubMenu key="submenu_facture" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-revenue-new"/>
                   <IntlMessages id="sidebar.components.facture"/>
                   </span>}>

                  <Menu.Item key="facture">
                    <Link to="/facture"><i className="icon icon-editor"/>
                      <IntlMessages id="sidebar.B2B.Facture"/></Link>
                  </Menu.Item>

                  <Menu.Item key="avoir">
                    <Link to="/avoir"><i className="icon icon-inbuilt-apps"/>
                      <IntlMessages id="sidebar.avoir"/></Link>
                  </Menu.Item>

                  <Menu.Item key="remise">
                    <Link to="/remise"><i className="icon icon-frequent"/>
                      <IntlMessages id="sidebar.remise"/></Link>
                  </Menu.Item>


                  <Menu.Item key="prelevement">
                    <Link to="/prelevement"><i className="icon icon-editor"/>
                      <IntlMessages id="sidebar.prelevement"/></Link>
                  </Menu.Item>

                </SubMenu>
              }
              {!isBack  &&
                <Menu.Item key="facture">
                  <Link to="/facture"><i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.B2B.Facture"/></Link>
                </Menu.Item>
              }


              {!isBack  &&
                <Menu.Item key="mes_documents">
                  <Link to="/documents"><i className="icon icon-folder-o"/>
                    <IntlMessages id="sidebar.B2B.Document"/></Link>
                </Menu.Item>
              }
              {!isBack  &&
                <Menu.Item key="mon_compte">
                  <Link to="/mon_compte"><i className="icon icon-avatar"/>
                    <IntlMessages id="sidebar.B2B.Compte"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <SubMenu key="submenu_commandes_fournisseur" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-orders"/>
                    <IntlMessages id="sidebar.commande_fournisseur"/>
                  </span>}>


                  <Menu.Item key="commande_a_passer">
                    <Link to="/commande_a_passer"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.commande_a_passer"/></Link>
                  </Menu.Item>

                  <Menu.Item key="commande_fournisseur_list">
                    <Link to="/commande_fournisseur"><i className="icon icon-shopping-cart"/>
                    <IntlMessages id="sidebar.commande_fournisseur_list"/></Link>
                  </Menu.Item>

                  <Menu.Item key="bons_reception">
                    <Link to="/bon_reception"><i className="icon icon-notification-new"/>
                    <IntlMessages id="sidebar.bons_reception"/></Link>
                  </Menu.Item>
                </SubMenu>
              }
              {isBack  &&
                <Menu.Item key="article">
                  <Link to="/article"><i className="icon icon-spin"/>
                    <IntlMessages id="sidebar.article"/></Link>
                </Menu.Item>
              }
              {isBack  &&
                <Menu.Item key="stock">
                  <Link to="/stock"><i className="icon icon-icon"/>
                    <IntlMessages id="sidebar.stock"/></Link>
                </Menu.Item>
              }

              {isBack &&
                <SubMenu key="submenu_composants" className={this.getNavStyleSubMenuClass(navStyle)} title={
                  <span>
                  <i className="icon icon-diamond"/>
                    <IntlMessages id="sidebar.components"/>
                  </span>}>

                  <Menu.Item key="composant">
                    <Link to="/composant"><i className="icon icon-widgets"/>
                      <IntlMessages id="sidebar.components.list"/></Link>
                  </Menu.Item>

                  <Menu.Item key="navette">
                    <Link to="/navette"><i className="icon icon-sent"/>
                      <IntlMessages id="sidebar.components.navette"/></Link>
                  </Menu.Item>
                  <Menu.Item key="reconditionnement">
                    <Link to="/reconditionnement"><i className="icon icon-chart-tree"/>
                      <IntlMessages id="sidebar.components.reconditionnement"/></Link>
                  </Menu.Item>

                </SubMenu>
              }

              {isBack  &&
                <Menu.Item key="client">
                  <Link to="/client"><i className="icon icon-signup"/>
                    <IntlMessages id="sidebar.client"/></Link>
                </Menu.Item>
              }


            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }



  render() {
    const {themeType, navStyle, pathname, authUser} = this.props;
    const selectedKeys    = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    const isBack          = authUser ? authUser.Adm : false;

    const CONST_Role      = authUser ? authUser.CONST_Role : '';

    console.log('CONST_Role')
    console.log(CONST_Role)

    const IsModeTest      = false;

    var IsPhonerBusiness  = false;
    var IsSogesco         = false;

    if(process.env.REACT_APP_ENV === 'PHONERBUSINESS'){
      IsPhonerBusiness = true;
    }



    if(process.env.REACT_APP_ENV === "SOGESCOT"){
      IsSogesco = true;

      return (
        <Auxiliary>
          <SidebarLogo/>
          <div className="gx-sidebar-content">
            <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            </div>

            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline">

                <Menu.Item key="dashboard">
                  <Link to="/dashboard"><i className="icon icon-data-display"/>
                    <IntlMessages id="sidebar.dashboard"/></Link>
                </Menu.Item>


                {isBack  &&
                  <Menu.Item key="intervention">
                    <Link to="/intervention"><i className="icon icon-visits"/>
                      <IntlMessages id="sidebar.interventions"/></Link>
                  </Menu.Item>
                }
                {isBack  &&
                  <Menu.Item key="client">
                    <Link to="/client"><i className="icon icon-signup"/>
                      <IntlMessages id="sidebar.client"/></Link>
                  </Menu.Item>
                }


                {isBack  &&
                  <SubMenu key="submenu_facture" className={this.getNavStyleSubMenuClass(navStyle)} title={
                    <span>
                    <i className="icon icon-revenue-new"/>
                     <IntlMessages id="sidebar.components.facture"/>
                     </span>}>

                    <Menu.Item key="facture">
                      <Link to="/facture"><i className="icon icon-editor"/>
                        <IntlMessages id="sidebar.B2B.Facture"/></Link>
                    </Menu.Item>

                    <Menu.Item key="avoir">
                      <Link to="/avoir"><i className="icon icon-inbuilt-apps"/>
                        <IntlMessages id="sidebar.avoir"/></Link>
                    </Menu.Item>

                    <Menu.Item key="remise">
                      <Link to="/remise"><i className="icon icon-frequent"/>
                        <IntlMessages id="sidebar.remise"/></Link>
                    </Menu.Item>

                  </SubMenu>
                }

                {!isBack  &&
                  <Menu.Item key="facture">
                    <Link to="/facture"><i className="icon icon-orders"/>
                      <IntlMessages id="sidebar.B2B.Facture"/></Link>
                  </Menu.Item>
                }
                {!isBack  &&
                  <Menu.Item key="mes_documents">
                    <Link to="/documents"><i className="icon icon-folder-o"/>
                      <IntlMessages id="sidebar.B2B.Document"/></Link>
                  </Menu.Item>
                }
                {!isBack  &&
                  <Menu.Item key="mon_compte">
                    <Link to="/mon_compte"><i className="icon icon-avatar"/>
                      <IntlMessages id="sidebar.B2B.Compte"/></Link>
                  </Menu.Item>
                }

                {isBack  &&
                  <Menu.Item key="article">
                    <Link to="/article"><i className="icon icon-spin"/>
                      <IntlMessages id="sidebar.article"/></Link>
                  </Menu.Item>
                }


                {isBack  &&
                  <Menu.Item key="parametrages">
                    <Link to="/parametrages"><i className="icon icon-setting"/>
                      <IntlMessages id="sidebar.parametrages"/></Link>
                  </Menu.Item>
                }


              </Menu>
            </CustomScrollbars>
          </div>
        </Auxiliary>
      );
    }else{

      if(CONST_Role == 'ADMINISTRATEUR'){
        return this.getMenuADMINISTRATEUR(selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest)
      }else if (CONST_Role == 'MANAGER'){
        return this.getMenuMANAGER(selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest)
      }else if (CONST_Role == 'TECHNICIEN'){
        return this.getMenuTECHNICIEN(selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest)
      }else{
        return this.getMenuTECHNICIEN(selectedKeys, navStyle, defaultOpenKeys, themeType, isBack, IsPhonerBusiness, IsModeTest)
      }

    }


  }
}


SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  const {authUser} = auth;
  return {navStyle, themeType, locale, pathname, authUser}
};
export default connect(mapStateToProps)(SidebarContent);
