// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

export const USER_TOKEN_SET = 'user_token_set';
export const USER_DATA = 'user_data';
export const USER_REGISTERING_SET = 'user_guid_set';

export const USER_DASHBOARD_DATA = 'user_dashboard_data'
export const USER_INTERVENTION_DATA = 'user_intervention_data'
export const USER_INTERVENTION_DATA_CLEAN = 'user_intervention_data_clean'

export const USER_FACTURE_DATA = 'user_facture_data'
export const USER_FACTURE_DATA_CLEAN = 'user_facture_data_clean'
export const USER_MODELE_DATA                 = 'user_modele_data'
export const USER_MODELE_DATA_CLEAN = 'user_modele_data_clean'
export const USER_AVOIR_DATA                  = 'user_avoir_data'
export const USER_AVOIR_DATA_CLEAN = 'user_avoir_data_clean'
export const USER_REMISE_DATA                 = 'user_remise_data'
export const USER_REMISE_DATA_CLEAN           = 'user_remise_data_clean'
export const USER_TELEPHONE_LIST_DATA         = 'user_telephone_list_data'
export const USER_PANIER_ACHAT_DATA_CLEAN     = 'user_panier_achat_data_clean'
export const USER_PANIER_ACHAT_DATA           = 'user_panier_achat_data'
export const USER_FLOTTE_DATA                 = 'user_flotte_data'
export const USER_FLOTTE_DATA_CLEAN           = 'user_flotte_data_clean'
export const USER_SIM_DATA                    = 'user_sim_data'
export const USER_SIM_DATA_CLEAN              = 'user_sim_data_clean'
export const USER_GENERATE_PARAM_LIST            ='user_generate_param_list'
