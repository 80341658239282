import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover, Button} from "antd";
import {Link} from "react-router-dom";
import {userSignOut} from "appRedux/actions/Auth";

class UserProfileTop extends Component {

  render() {
    const {authUser} = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut()}>Se déconnecter
        </li>
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center ">
        <div style={{ marginTop: 12, marginRight: 18 }}>
          <Link to="/panier_achat"> <Button type="primary" icon="shopping-cart">Mon panier</Button></Link>
        </div>

      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <span className="gx-pointer gx-flex-row gx-align-items-center">
          <div style={{ color: "#ffffff" }}>
          <span className="">{authUser ? authUser.Nom + ' ' + authUser.Prenom : "Loading"}</span>
          </div>
          <div style={{color: "#ffffff", marginRight: 18 }}>
            <i className="icon icon-chevron-down gx-pl-2"/>
          </div>
        </span>
      </Popover>
      <Avatar src={require(`assets/images/${process.env.REACT_APP_ENV}/profil.jpg`)}
            className="gx-size-40 gx-pointer gx-mr-3" alt=""/>

      </div>
    )
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignOut})(UserProfileTop);
